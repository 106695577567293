.match-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  color: black;
  align-items: center;
  border-radius: 4px;
  background-color: #555;
  color: white;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0px 0px 5px 5px #2f2f2fe6;
  overflow: hidden;
}

.match-container:hover {
  transition: 0.2s;
  box-shadow: 0px 0px 6px 6px #222222e6;
}

.team-stats:first-of-type {
  width: 500px;
}

.team-stats:first-of-type .player-name {
  font-weight: bold;
  color: rgb(255, 127, 127);
}

.team-stats:last-of-type .player-name {
  font-weight: bold;
  color: rgb(127, 187, 255);
}

.team-stats:last-of-type {
  width: 500px;
}

.match-info {
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: black;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  background-color: #555;
  color: white;
}

.match-container .left-div {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-left: 20px;
  width: 100px;
}

.match-container .wildcard-icon {
  margin-right: 5px;
  width: 18px;
  height: 18px;
  padding-top: 3px;
  vertical-align: middle;
  justify-content: center;
}

.match-container .wildcard {
  vertical-align: middle;
  justify-content: left;
  display: flex;
}

.match-container .center-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.match-container .left-team-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.match-container .right-team-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.match-container .right-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 20px;
  width: 100px;
}

.match-container .team-logo {
  width: 35px;
  height: 35px;
}

.result {
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-right: 3px;
  text-align: center;
  width: 40px;
  justify-content: center;
}

.vs {
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-right: 3px;
  text-align: center;
  width: 40px;
  justify-content: center;
}

.match-container .play-icon {
  margin-right: 5px;
}

.match-container .stats-container {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  width: 100%;
  padding-bottom: 5px;
}

.match-container .stats-container div {
  flex: 1;
}

h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
  color: #ccc;
  border-bottom: 1px solid #ccc;
}

.expanded {
  background-color: #f8f8f8;
  background-color: #555;
  color: white;
}

.match-container .player-stats {
  align-self: center;
  align-items: center;
  justify-content: left;
  text-align: left;
  display: flex;
  gap: 4px;
  width: 400px;
  margin-left: 200px;
  margin-right: 100px;
}

.match-container .left-wildcard-defwin {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  vertical-align: middle;
  font-size: 13px;
  padding-top: 3px;
  background: none !important;
}

.match-container .left-wildcard-defwin .wildcard-icon {
  width: 12px;
  padding-top: 4px;
  height: 12px;
  background: none !important;
}

.center-div {
  width: 800px;
  display: flex;
  justify-content: center;
}

.right-team-container {
  width: 350px;
  display: flex;
  justify-content: flex-start !important;
}

.left-team-container {
  width: 350px;
  display: flex;
  justify-content: flex-end !important;
}
