/* SpecificPlayerPage.css */
.specific-player-page {
  margin-top: 60px;
}

.specific-player-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 28px;
  background: #333;
  width: 1000px;
  flex-shrink: 0;
  /* padding-top: 126px; */
  padding-top: auto;
  padding-bottom: auto;
  align-items: center;
  padding: 40px !important;
  box-shadow: 0px 0px 10px 5px #222222e6;
}

.specific-player-container:hover {
  transition: 0.2s;
  box-shadow: 0px 0px 12px 7px #222222e6;
}

.specific-player-header {
  display: flex;
  /* border: 3px solid red !important; */
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.specific-player-header .specific-player-name {
  color: white;
  font-weight: bolder;
  font-size: 40px;
}

.specific-player-header .specific-team-logo {
  width: 100px;
  height: 100px;
  margin-right: 30px;
  /* border: 2px solid yellowgreen; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid red;
}

.specific-team-logo img {
  margin: 0;
  padding: 0;
  width: 100px;
  height: 100px;
}

.logo-name-container {
  display: flex;
  align-items: center;
  /* border: 2px solid aqua; */
}

.exit-button {
  width: 20px;
  height: 20px;
  font-size: 35px;
  padding-bottom: 90px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
}

.exit-button2:hover {
  transition: 0.2s;
  color: whitesmoke;
  color: rgb(202, 43, 43);
}

.exit-button2 {
  color: #ccc;
}
