.player-stats2 {
  display: flex;
  width: 700px;
  /* border: 2px solid orange; */
  font-size: 20px;
  color: white;
  align-items: center;
  justify-content: left;

  p {
    margin: 4px 8px;
  }
}

.player-name30 {
  font-weight: bold;
  background-image: linear-gradient(to right bottom, #6555f4, #6a5aff, #009cdf, #00acc1, #39b8a7);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.captain-icon2 {
  width: 30px;
  height: 30px;
  /* border: 1px solid lightcoral; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-stats2 .coins1-1 {
  color: #a3bdba !important;
}

.player-stats2 .coins2-1 {
  color: #fdd550 !important;
}

.player-stats2 .coins3-1 {
  color: #cc87eb !important;
}
