.playoff-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  align-items: center;
}

.title-header {
  color: white;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  font-size: 22px;
  font-weight: bolder;
  text-align: center;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 50px;
}

.playoff-page-container .title-text {
  padding: 20px;
  border-radius: 7px;
  background-color: #333333;
  box-shadow: 0px 0px 6px 4px #222222e6;
  margin: auto;
  width: auto;
  color: white;
  min-width: 300px;
  padding-right: 40px;
}

.playoff-page-container .title-text:hover {
  transition: 0.2s;
  background-color: #333333;
  transform: translateY(-3px);
  box-shadow: 0px 0px 10px 5px #222222e6;
  color: white;
}

.fa-icon2 {
  width: 23px;
  height: 23px;
  vertical-align: middle;
}

.playoff-board {
  width: 80%;
  min-height: 300px;
  /* border: 3px solid red; */
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.quarter-finals {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 30%;
  min-height: 400px;
  /* border: 2px solid yellowgreen; */
  background-color: #3b3b3b;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 6px 4px #222222e6;
}

.quarter-finals:hover {
  transition: 0.2s;
  box-shadow: 0px 0px 10px 5px #222222e6;
}

.semi-finals {
  display: flex;
  align-items: flex-start;
  width: 30%;
  min-height: 400px;
  flex-direction: column;
  /* border: 2px solid blue; */
  background-color: #3b3b3b;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 6px 4px #222222e6;
}

.semi-finals:hover {
  transition: 0.2s;
  box-shadow: 0px 0px 10px 5px #222222e6;
}

.final {
  display: flex;
  align-items: flex-start;
  width: 30%;
  min-height: 400px;
  flex-direction: column;
  /* border: 2px solid aqua; */
  background-color: #3b3b3b;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 6px 4px #222222e6;
}

.final:hover {
  transition: 0.2s;
  box-shadow: 0px 0px 10px 5px #222222e6;
}

.round-title {
  background-color: #333333;
  color: white;
  font-size: 20px;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.date {
  background-color: #616161;
  font-size: 13px;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7%;
}

.round-matches {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 96%;
  gap: 10px;
  align-self: center;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
  min-height: 332px;
  /* border: 2px solid lightcyan; */
}

.playoff-match {
  /* border: 2px solid fuchsia; */
  width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.match-stats-container {
  width: 80%;
  min-height: 50px;
  border: 3px solid white;
  margin-top: 50px;
}
