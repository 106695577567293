.knockout-container {
  min-width: 1200px;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  background-color: #c5c5c5;
  color: black;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  margin-top: 50px;
}

.knockout-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 26px;
}

.knockout-stats {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  align-items: flex-start;
  margin-bottom: 15px;
  border-top: 3px solid black;
  padding-top: 13px;
  /* border: 3px solid red; */
}

.knockout-result {
  font-size: 20px;
  font-weight: bold;
  background-color: #333;
  border-radius: 5px;
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
  color: #ccc;
}

.left-team {
  /* border: 2px solid blue; */
  width: 45%;
  text-align: center;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-items: center;

  align-self: flex-start;
}

.right-team {
  /* border: 2px solid greenyellow; */
  width: 45%;
  text-align: center;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-items: center;
  align-self: flex-start;
}

.stats-title {
  font-size: 24px;
  color: #4c4b4b;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* border: 3px solid orange; */
  width: 99%;
  padding-right: 8px;
}

.stats-container2 {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px solid blueviolet; */
  justify-content: left;
  align-items: flex-start;
  padding-left: 20px;
}

.stats-icon {
  margin-right: 3px;
}

.knockout-stats .player-stats {
  align-self: center;
  align-items: center;
  justify-content: left;
  text-align: left;
  display: flex;
  gap: 4px;
  width: 400px;
  margin-left: 200px;
  margin-right: 100px;
}

.knockout-stats .player-name {
  font-size: 20px;
  font-weight: bold;
}
