/* PlayersPage.css */

.players-page {
  text-align: center;
  margin-top: 20px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #333;
  background-color: #333;
}

.search-bar {
  position: relative;
  /* Per posizionare l'icona all'interno della barra di ricerca */
  background-color: #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: auto;
  max-width: 800px;
  min-width: 500px;
}

.search-bar:hover {
  box-shadow: 0px 0px 10px 5px #222222e6;
}

.search-icon {
  margin: 0 8px;
  /* Aggiungi spazio tra l'icona e l'input */
  color: #333;
  /* Colore dell'icona */
  vertical-align: middle;
}

.search-input {
  border: none;
  outline: none;
  background: transparent;
  color: #333;
  flex: 1;
  padding: 10px;
  border-radius: 5px;
}

.search-input:hover {
  box-shadow: 0px 0px 0px 0px #222222e6;
}

.player-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
  max-width: 1500px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

@media (max-width: 768px) {
  .player-cards-container {
    flex-direction: column;
    /* Una colonna su schermi più piccoli */
  }
}

.title-header {
  color: white;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  font-size: 22px;
  font-weight: bolder;
  text-align: center;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 50px;
}

.players-page .title-text {
  padding: 20px;
  border-radius: 7px;
  background-color: #333333;
  box-shadow: 0px 0px 6px 4px #222222e6;
  margin: auto;
  width: auto;
  color: white;
  min-width: 300px;
}

.players-page .title-text:hover {
  transition: 0.2s;
  background-color: #333333;
  transform: translateY(-3px);
  box-shadow: 0px 0px 10px 5px #222222e6;
  color: white;
}

.fa-icon2 {
  width: 23px;
  height: 23px;
  vertical-align: middle;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.search-box {
  margin: 0;
  padding-right: 20px;
}

.right-filter-button {
  /* margin-left: 10px; */
  /* Aggiungi uno spazio tra la casella di ricerca e il bottone */
  width: 200px;
}

.right-filter-button button {
  padding: 10px 15px;
  background-color: #4caf50;

  background-color: #333333;
  box-shadow: 0px 0px 6px 4px #222222e6;
  /* Colore di sfondo del bottone */
  color: white;
  /* Colore del testo del bottone */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.right-filter-button button:hover {
  transition: 0.2s;
  background-color: #45a049;
  box-shadow: 0px 0px 6px 4px #222222e6;
  /* Cambia il colore di sfondo al passaggio del mouse */
}

.left-search-empty-box {
  width: 200px;
}
