/* Login.css */
.body {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.login-container {
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;
  background-color: #333;
  border-radius: 8px;
  padding: 20px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 0px 10px 5px #222222e6;
  -ms-transform: translateY(60%);
  transform: translateY(60%);
}

.login-container:hover {
  transition: 0.2s;
  box-shadow: 0px 0px 12px 7px #222222e6;
}

.login-container h2 {
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  width: 91%;
}

.login-container h2:hover {
  transition: 0.2s;
  transform: translateY(-3px);
  box-shadow: 0px 0px 10px 5px #222222e6;
  background-color: #5b5a5a;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

input {
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: #ccc;
  color: #333;
  font-size: 16px;
  width: 91%;
  /* Imposta la larghezza al 100% */
}

input:hover {
  transition: 0.2s;
  box-shadow: 0px 0px 10px 8px #222222e6;
}

.login-button {
  padding: 12px;
  /* background-color: #007BFF; */
  background-color: #6e78f7;
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 97%;
  /* Imposta la larghezza al 100% */
}

.login-button:hover {
  transition: 0.6s;
  background-color: #0056b3;
  /* background-color: #1424c2; */
  box-shadow: 0px 0px 10px 5px #222222e6;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.fa-icon {
  margin-right: 8px;
  /* Aggiunge spazio tra l'icona e il testo */
  width: 28px;
  height: 28px;
  padding-bottom: 1px;
}

.fa-icon2 {
  margin-right: 8px;
  /* Aggiunge spazio tra l'icona e il testo */
  width: 15px;
  height: 15px;
  padding-bottom: 1px;
  vertical-align: center;
  align-items: center;
  padding-bottom: 2%;
  padding-left: 4%;
}
